.heading___BBQIo {
  margin: 0;
  padding: 0;
  color: var(--text-primary, #0e0e0e);
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 400;
}
.h1___2oyOi {
  margin: 0;
  padding: 0;
  color: var(--text-primary, #0e0e0e);
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 400;
  font-size: 42px;
  line-height: 42px;
  letter-spacing: -0.04em;
}
@media (min-width: 768px) {
  .h1___2oyOi {
    font-size: 64px;
    line-height: 64px;
  }
}
.h2___JkJWz {
  margin: 0;
  padding: 0;
  color: var(--text-primary, #0e0e0e);
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.04em;
}
@media (min-width: 768px) {
  .h2___JkJWz {
    font-size: 48px;
    line-height: 48px;
  }
}
.h3___8Vf6y {
  margin: 0;
  padding: 0;
  color: var(--text-primary, #0e0e0e);
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.04em;
}
@media (min-width: 768px) {
  .h3___8Vf6y {
    font-size: 32px;
    line-height: 40px;
  }
}
.h4___1ISas {
  margin: 0;
  padding: 0;
  color: var(--text-primary, #0e0e0e);
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.03em;
}
@media (min-width: 768px) {
  .h4___1ISas {
    font-size: 24px;
    line-height: 32px;
  }
}
.h5___24wGA {
  margin: 0;
  padding: 0;
  color: var(--text-primary, #0e0e0e);
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: normal;
}
@media (min-width: 768px) {
  .h5___24wGA {
    font-size: 20px;
    line-height: 24px;
  }
}
.h6___2e46W {
  margin: 0;
  padding: 0;
  color: var(--text-primary, #0e0e0e);
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: normal;
}
.strong___3Pz99 {
  font-weight: 600;
}
.serif___3vbEn {
  font-family: var(--font-family-serif, 'new-spirit', 'helvetica-bold', serif);
  font-weight: var(--heading-font-family-serif-weight, 500);
}
.primary___Kvl8j {
  color: var(--text-primary, #0e0e0e);
}
.secondary___1adpN {
  color: var(--text-secondary, #4a4a4a);
}
.tertiary___1tvXg {
  color: var(--text-tertiary, #757575);
}
.disabled___3N3DA {
  color: var(--system-disabled, #b7b7b7);
}
.invert___3N4bR {
  color: var(--text-invert, #ffffff);
}

.zola-ui.circle-icon {
  border-radius: 50%;
}
.zola-ui.circle-icon.cream {
  background-color: #f6f5f0;
}
.zola-ui.circle-icon.white {
  background-color: #ffffff;
}
.zola-ui.circle-icon.transparent {
  background-color: transparent;
}

.v2-button {
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  border-radius: 100px;
  height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}
@media (min-width: 768px) {
  .v2-button {
    height: 44px;
  }
}
.v2-button.button--compact {
  font-size: 14px;
  height: 38px;
}
.v2-button.button--fullWidth {
  width: 100%;
  flex-basis: 100%;
}
.v2-button:disabled {
  cursor: default;
}
.v2-button:focus {
  outline: 5px auto -webkit-focus-ring-color;
}
a.v2-button {
  justify-content: center;
  align-items: center;
  user-select: none;
  text-align: center;
  text-decoration: none;
}
a.v2-button:active,
a.v2-button:focus,
a.v2-button:hover {
  text-decoration: none;
}
.v2-button[role='link'] {
  background-color: transparent;
  border-radius: unset;
}
.v2-button.primary-button {
  background-color: #0e0e0e;
  color: #ffffff;
}
.v2-button.primary-button:hover {
  color: #ffffff;
  background-color: #4a4a4a;
}
.v2-button.primary-button:active {
  background-color: #0e0e0e;
}
.v2-button.primary-button:disabled {
  background-color: #b7b7b7;
}
.v2-button.secondary-button {
  background-color: #ffffff;
  color: #0e0e0e;
  border-color: #0e0e0e;
}
.v2-button.secondary-button:hover {
  background-color: #f3f3f3;
  color: #0e0e0e;
  border-color: #0e0e0e;
}
.v2-button.secondary-button:active {
  background-color: #ffffff;
  color: #0e0e0e;
}
.v2-button.secondary-button:disabled {
  background-color: #ffffff;
  color: #b7b7b7;
  border-color: #b7b7b7;
}
.v2-button.tertiary-button {
  background-color: #ffffff;
  color: #c7305b;
  border-color: #c7305b;
}
.v2-button.tertiary-button:hover {
  background-color: #fcf5f7;
}
.v2-button.tertiary-button:active {
  background-color: #ffffff;
}
.v2-button.tertiary-button:disabled {
  background-color: #fcf5f7;
  color: #eec1ce;
  border-color: #eec1ce;
}
.v2-button.transparent-button {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
}
.v2-button.transparent-button:disabled {
  background-color: #f3f3f3;
  color: #b7b7b7;
}
.v2-button.danger-button {
  background-color: #c7305b;
  color: #ffffff;
}
.v2-button.danger-button:hover {
  background-color: #cf4f74;
}
.v2-button.danger-button:active {
  background-color: #c7305b;
}
.v2-button.danger-button:disabled {
  background-color: #eec1ce;
}

